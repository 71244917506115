const { LockManager: _LockManager } = require('navigator.locks');
// After moving bbwallet to login, we need web3.utils.stripHexPrefix
// For now, we borrow that method and put in bobob-auth
// No, metamask login also dependent on web3, put it back here.
const Web3 = require('web3');
// const utils = require('web3-utils');
// const eth = require('web3-eth');
// const Web3 = require('../public/js/web3.min.js');
// const _jquery = require('jquery');
const OAuth2AuthCodePKCE = require('../public/js/index.umd.js');
const jquery = require('../public/js/jquery-3.7.1.min.js');

if (typeof window !== 'undefined') {
    window.$ = window.jQuery = jquery;
}
// after jquery attachment to window above
const bootstrap = require('../public/js/bootstrap/5.3.2/js/bootstrap.bundle.min.js');

if (typeof window !== 'undefined') {
    window.bootstrap = bootstrap;
    window.Web3 = Web3;
    // window.Web3 = function() {
    //     this.utils = utils;
    //     this.eth = eth;
    // };
    window.OAuth2AuthCodePKCE = OAuth2AuthCodePKCE;
}
